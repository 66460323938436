import Image from 'next/image';
import styled from 'styled-components';
import Link from 'next/link';
import { UpperText } from '@/components/core/Title';
import Shape from '@/public/assets/full-shape.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 440px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: min-content;
    padding-bottom: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 32px;
  }
`;

export const Content = styled.div`
  min-width: 448px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  z-index: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin: 0 auto;
    align-items: center;
    min-width: 100%;
    text-align: center;
  }
`;

export const UpperLabel = styled(UpperText)`
  opacity: 1;
  letter-spacing: 2px;
  padding-bottom: 16px;
  color: ${({ theme }) => theme.colors.grey.default};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 8px;
  }
`;

export const Subtitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 32px;
  color: ${({ theme }) => theme.colors.grey.default};
  font-family: ${({ theme }) => theme.fonts.body};
  max-width: 640px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 24px;
  }
`;

export const ImageWrapper = styled.div`
  height: 440px;
  width: 620px;
  position: relative;
  z-index: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

export const CustomImage = styled(Image)`
  max-width: 620px;
  height: 440px;
  border-radius: 16px;
  object-fit: cover;
`;

export const FullShape = styled(Shape)`
  position: absolute;
  right: -45px;
  bottom: -105px;
  z-index: -1;
`;

export const TextArrowButton = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-family: ${(props) => props.theme.fonts.title};
  color: ${(props) => props.theme.colors.primary.default};
  cursor: pointer;
  transition: all 0.3s ease;

  svg {
    margin-left: 8px;
    transition: all 0.3s ease;

    path {
      fill: ${(props) => props.theme.colors.primary.default};
    }
  }

  &:hover {
    color: ${(props) => props.theme.colors.primary.dark};
    svg {
      transform: translateX(3px);
      path {
        fill: ${(props) => props.theme.colors.primary.dark};
      }
    }
  }
`;
