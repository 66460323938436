import { useContext } from 'react';
import Link from 'next/link';

import { RedButton } from '@/components/core/Button';
import { Container } from '@/components/core';

import { CustomTitle, Wrapper, UpperLabel, Subtitle } from './styles';

import EventContext from '@/contexts/event';
import { useUrlAttributes } from '@/utils/useUrlAttributes';

const Content = () => {
  const { info, joinRemotely } = useContext(EventContext);

  const { buttonLabel, label, title, upperLabel } = joinRemotely;
  const urlAttributes = useUrlAttributes(info.link);

  return (
    <Wrapper>
      <Container>
        <UpperLabel>{upperLabel}</UpperLabel>
        <CustomTitle className="" as="h2">
          {title}
        </CustomTitle>
        <Subtitle>{label}</Subtitle>
        {info.link && (
          <RedButton big href={info.link} forwardedAs={Link} {...urlAttributes}>
            {buttonLabel ?? 'Join'}
          </RedButton>
        )}
      </Container>
    </Wrapper>
  );
};

export default Content;
