import Speakers from './Speakers';
import Schedule from './Schedule';
import { SpeakerProps } from './Speakers/Speaker';
import { EventType } from './Schedule/Event';

const Event = ({
  event,
  showSpeakers = true,
  showSchedule = true,
}: {
  event: {
    speakers: SpeakerProps[] | null;
    schedule: EventType[];
  };
  showSpeakers?: boolean;
  showSchedule?: boolean;
}) => {
  const hasSpeakers = !!event.speakers?.length;

  return (
    <>
      {showSpeakers && hasSpeakers && <Speakers speakers={event.speakers as SpeakerProps[]} />}
      {showSchedule && <Schedule schedule={event.schedule} isSpacer={!hasSpeakers} />}
    </>
  );
};

export default Event;
