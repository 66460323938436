import { PropsWithChildren } from 'react';
import cn from 'classnames';
import styled, { css } from 'styled-components';
import AnimateHeight from 'react-animate-height';

import Content from '@/components/core/Accordion/Content';
import AccordionItemTitle from '@/components/core/Accordion/AccordionItemTitle';

type AccordionItemStyledTypeStyled = {
  transparentBorders?: boolean;
};

const TitleWrapper = styled.h3`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const TitleText = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  padding-right: 16px;

  &.is-open {
    color: ${(props) => props.theme.colors.grey.dark};
  }
`;

const TitleIcon = styled.i`
  min-width: 15px;
  min-height: 15px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 2px;
    background-color: #a4aab6;
    border-radius: 120px;
    transition: transform 500ms;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(270deg);
  }

  &.is-open:after {
    transform: translate(-50%, -50%) rotate(0deg);
    background-color: #1d3861;
  }
`;

const ContentWrapper = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;

  &:before {
    content: '';
    height: 24px;
    width: 100%;
    display: block;
    opacity: 0;
  }

  &:after {
    content: '';
    height: 8px;
    width: 100%;
    display: block;
    opacity: 0;
  }
`;

const AccordionItemStyled = styled.li<AccordionItemStyledTypeStyled>`
  ${({ theme, transparentBorders }) => css`
    h3 {
      &.title-wrapper span {
        color: ${theme.colors.white};
      }
      &.title-wrapper i {
        background-color: ${theme.colors.grey.default};
        &:before,
        &:after {
          background-color: ${theme.colors.white};
        }
      }
    }

    padding: 24px 0;
    border-bottom: 1px solid ${transparentBorders ? 'transparent' : theme.colors.grey[800]};

    &:last-child {
      border-bottom: 0;
    }

    @media (min-width: ${theme.breakpoints.min_md}px) {
      padding: 24px 64px;
    }
  `}
`;

type AccordionItemStyledType = {
  className?: string;
  id?: number;
  title?: string;
  expanded?: boolean;
  handleClick?: any;
  indexedTitle?: boolean;
  index?: number;
  transparentBorders?: boolean;
};

export default function AccordionItem({
  className,
  id,
  title,
  children,
  expanded = false,
  handleClick,
  indexedTitle = false,
  index,
  transparentBorders,
}: PropsWithChildren<AccordionItemStyledType>) {
  return (
    <AccordionItemStyled className={className} transparentBorders={transparentBorders}>
      <TitleWrapper
        className="title-wrapper"
        aria-controls={`panel_${id}`}
        onClick={() => handleClick()}
      >
        {indexedTitle ? (
          <AccordionItemTitle className={cn({ 'is-open': expanded })} title={title} index={index} />
        ) : (
          <>
            <TitleText className={cn({ 'is-open': expanded })}>{title}</TitleText>
            <TitleIcon className={cn({ 'is-open': expanded })} />
          </>
        )}
      </TitleWrapper>

      <AnimateHeight id={`panel_${id}`} duration={500} height={expanded ? 'auto' : 0}>
        <ContentWrapper>
          <Content>{children}</Content>
        </ContentWrapper>
      </AnimateHeight>
    </AccordionItemStyled>
  );
}
