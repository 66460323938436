import React from 'react';

import { Wrapper, Stats, Description } from './styles';

type CardProps = {
  stats: string;
  description: string;
};

export default function Card({ stats, description }: CardProps) {
  return (
    <Wrapper>
      <Stats>{stats}</Stats>
      <Description>{description}</Description>
    </Wrapper>
  );
}
