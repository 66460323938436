import styled from 'styled-components';

import { Title, UpperText } from '@/components/core/Title';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const UpperLabel = styled(UpperText)`
  opacity: 1;
  letter-spacing: 2px;
  padding-bottom: 16px;
  color: #c5c9d2;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 8px;
  }
`;

export const CustomTitle = styled(Title)`
  font-weight: 700;
  font-size: 55px;
  line-height: 64px;
  padding-bottom: 16px;
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 45px;
    line-height: 56px;
    padding-bottom: 8px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 25px;
    line-height: 32px;
  }
`;

export const Subtitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 32px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.body};

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 24px;
  }
`;
