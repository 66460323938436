import styled from 'styled-components';
import { useContext } from 'react';

import { ItsTime } from '@/components/shared';
import Display from './Display/Display';

import theme from '@/utils/styles-variables';

import EventContext from '@/contexts/event';

const DataTimeDisplay = () => {
  const { status, header, info } = useContext(EventContext);

  const { successLabel } = header;
  const { link, joinButtonLabel } = info;

  return (
    <TimerWrapper>
      {status === 'during' && (
        <ItsTime successLabel={successLabel} link={link} joinButtonLabel={joinButtonLabel} />
      )}
      {status === 'before' && <Display />}
    </TimerWrapper>
  );
};

export const TimerWrapper = styled.div`
  width: 100%;
  max-width: 272px;
  z-index: 6;
  min-height: 104px;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    max-width: 70%;
  }
`;

export default DataTimeDisplay;
