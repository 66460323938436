import moment from 'moment';

import {
  InnerWrapper,
  Item,
  OuterWrapper,
  Wrapper,
  DesktopDate,
  MobileDate,
  Content,
} from './styles';

type Date = {
  date: string;
};

const Nav = ({
  dates,
  active,
  handleClick,
  fixed,
}: {
  dates: Date[];
  active: number;
  handleClick: Function;
  fixed: boolean;
}) => (
  <Content>
    <Wrapper $fixed={fixed}>
      <OuterWrapper>
        {dates.map((item, index) => (
          // @ts-ignore
          <InnerWrapper key={index} $active={active === index} onClick={() => handleClick(index)}>
            <Item>
              <DesktopDate>{moment(item.date).format('dddd, D')}</DesktopDate>
              <MobileDate>{moment(item.date).format('ddd, D')}</MobileDate>
            </Item>
          </InnerWrapper>
        ))}
      </OuterWrapper>
    </Wrapper>
  </Content>
);

export default Nav;
