import React, { useContext } from 'react';

import { getImgSrcWorkaround } from '@/utils/images';

import {
  Wrapper,
  Content,
  UpperLabel,
  Subtitle,
  ImageWrapper,
  CustomImage,
  FullShape,
  TextArrowButton,
} from './styles';
import { CustomTitle } from '@/components/shared';
import Chevron from '@/public/assets/chevron-black.svg';

import EventContext from '@/contexts/event';

export default function Header() {
  const { event } = useContext(EventContext);
  const { upperLabel, title, label, link, buttonLabel, firstImage } = event;

  return (
    <Wrapper>
      <Content>
        <UpperLabel $type="primary">{upperLabel}</UpperLabel>
        <CustomTitle className="" as="h2">
          {title}
        </CustomTitle>
        <Subtitle>{label}</Subtitle>
        {link && (
          <TextArrowButton href={link} target="_blank">
            {buttonLabel ?? 'Join'} <Chevron />
          </TextArrowButton>
        )}
      </Content>
      <ImageWrapper>
        <FullShape />
        <CustomImage
          fill
          sizes="100%"
          src={getImgSrcWorkaround(firstImage.data.attributes.url)}
          alt={firstImage.data.attributes.alternativeText || firstImage.data.attributes.name}
        />
      </ImageWrapper>
    </Wrapper>
  );
}
