import React, { useContext } from 'react';

import { Section } from '@/components/core';
import { Wrapper, CircleShape } from './styles';
import Triangles from './Triangles';
import Content from './Content';
import theme from '@/utils/styles-variables';

import EventContext from '@/contexts/event';

const Join = ({ minimal = false }) => {
  const { canJoin, joinRemotely, hideJoin } = useContext(EventContext);

  if (!canJoin || !joinRemotely || hideJoin) return null;

  return (
    <Section
      color={theme.colors.grey.dark}
      {...(minimal
        ? {}
        : {
            paddingXs: { top: 60, bottom: '0' },
            paddingSm: { top: 120, bottom: '0' },
            paddingMd: { top: 100, bottom: '0' },
          })}
    >
      <Wrapper $minimal={minimal}>
        <Content />
        {!minimal && (
          <>
            <CircleShape />
            <Triangles />
          </>
        )}
      </Wrapper>
    </Section>
  );
};

export default Join;
