import styled, { css } from 'styled-components';

type WrapperType = {
  $fixed: boolean;
};

const wrapperModifiers = {
  fixed: () => css`
    position: fixed;
    top: 92px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 4px 12px rgba(121, 100, 90, 0.3);

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      top: 44px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      top: 60px;
    }
  `,
};

export const Content = styled.div`
  min-height: 56px;
  margin: 32px auto 0;
`;

export const Wrapper = styled.div<WrapperType>`
  ${({ $fixed }) => css`
    background-color: ${(props) => props.theme.colors.neutral.dark};
    border-radius: 300px;
    max-width: fit-content;
    width: 100%;
    margin: 0 auto;
    z-index: 6;

    ${$fixed && wrapperModifiers.fixed()};

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      overflow: auto;
      white-space: nowrap;
      scrollbar-width: none; // hide scrollbar in Firefox
      margin-top: 24px;
      ::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        margin-top: 8px;
      }
    }
  `}
`;

export const OuterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;

export const Item = styled.div`
  font-family: ${(props) => props.theme.fonts.title};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const DesktopDate = styled.div`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    display: unset;
  }
`;

export const MobileDate = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: unset;
  }
`;

export const InnerWrapper = styled.div<{ $active?: boolean }>`
  background-color: transparent;
  border-radius: 300px;
  padding: 8px 16px;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.neutral.dark};

    ${Item} {
      color: ${(props) => props.theme.colors.primary.default};
      transition: 0.2s;
      transition-timing-function: ease-in-out;
    }
  }

  ${(props) =>
    props.$active &&
    css`
      background-color: ${(props) => props.theme.colors.primary.light};
      transition: background-color 0.3s ease-in-out;

      ${Item} {
        color: ${(props) => props.theme.colors.primary.dark};
        transition: 0.3s;
        transition-timing-function: ease-in-out;
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.primary.light};
        ${Item} {
          color: ${(props) => props.theme.colors.primary.dark};
        }
      }
    `}
`;
