import { useContext, useMemo } from 'react';
import cn from 'classnames';
import EventContext from '@/contexts/event';
import { themeEventName } from '@/utils/themeEventName';
import Event, { EventType } from './Event';
import { Grid, Information } from './scheduleStyles';

export type ScheduleType = {
  className?: string;
  schedule: EventType[];
  isSpacer?: boolean;
};

const Schedule = ({ className, isSpacer, schedule }: ScheduleType) => {
  const { agenda, themeEvent } = useContext(EventContext);
  const isAiSummit = useMemo(() => themeEvent === themeEventName.aiSummit, [themeEvent]);

  if (!schedule) return null;

  return (
    <>
      <Information className={cn(className, { isAiSummit })} $spacer={isSpacer}>
        {agenda?.scheduleLabel || 'All times'}
      </Information>
      <Grid>
        {schedule.map((entry) => (
          <Event {...entry} key={entry.id} className={className} />
        ))}
      </Grid>
    </>
  );
};

export default Schedule;
