import styled from 'styled-components';

import Left from '@/public/assets/join-left-triangle.svg';
import Center from '@/public/assets/join-left-triangle.svg';
import Right from '@/public/assets/join-left-triangle.svg';

const Triangles = () => {
  return (
    <TrianglesWrapper>
      <LeftShape />
      <CenterShape />
      <RightShape />
    </TrianglesWrapper>
  );
};

const TrianglesWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 1336px;
  z-index: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    transform: scale(0.71);
    top: 10px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    transform: scale(0.53);
  }
`;

const LeftShape = styled(Left)`
  position: absolute;
  top: 165px;
  left: -41px;
  z-index: 1;
`;

const CenterShape = styled(Center)`
  position: absolute;
  top: 30px;
  z-index: -1;
`;

const RightShape = styled(Right)`
  position: absolute;
  top: 265px;
  right: -124px;
  z-index: 0;
`;

export default Triangles;
