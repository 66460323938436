import styled from 'styled-components';

import { H2, UpperText } from '@/components/core/Title';

export const Wrapper = styled.div`
  position: relative;

  & ${H2}, & ${UpperText} {
    text-align: center;
  }
`;
