import { PropsWithChildren } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGFM from 'remark-gfm';
import styled from 'styled-components';
import { wysiwyg } from '@/components/core/Accordion/wysiwyg';

const ContentStyle = styled.div`
  ${wysiwyg};
`;

const Content = ({ children }: PropsWithChildren) => (
  <ContentStyle>
    <ReactMarkdown
      remarkPlugins={[[remarkGFM, remarkBreaks]]}
      unwrapDisallowed
      allowedElements={[
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'ul',
        'ol',
        'li',
        'blockquote',
        'p',
        'strong',
        'em',
        'del',
        'code',
        'img',
        'a',
      ]}
      components={{
        h1: 'h3',
        h2: 'h3',
      }}
    >
      {/* @ts-ignore */}
      {children}
    </ReactMarkdown>
  </ContentStyle>
);

export default Content;
