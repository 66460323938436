import styled, { css } from 'styled-components';

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 64px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 0;
  }
`;

export const Information = styled.div<{ $spacer?: boolean }>`
  font-family: ${(props) => props.theme.fonts.body};
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: ${(props) => `var(--label-color, ${props.theme.colors.grey.default})`};
  margin-top: 16px;

  &.isAiSummit {
    font-family: var(--ui-font-family-space-mono);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 12px;
    margin-top: 8px;
  }

  ${(props) =>
    props.$spacer &&
    css`
      padding-top: 48px;

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        padding-top: 24px;
      }
    `};
`;
