import { useContext, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Container, Section } from '@/components/core';
import { H2, UpperText } from '@/components/core/Title';

import { navigateToId } from '@/components/core/Navigation/Navigation';

import theme from '@/utils/styles-variables';

import Nav from './Nav';
import Event from './Event';

import { Wrapper } from './styles';

import EventContext from '@/contexts/event';

const Agenda = () => {
  const { agenda, agendaEntries } = useContext(EventContext);

  const [active, setActive] = useState(0);
  const { upperLabel, title, hideAgenda } = agenda;

  const [ref, inView] = useInView({
    threshold: 0,
    initialInView: false,
    rootMargin: '-80px 0% -80%',
  });

  if (hideAgenda) return null;

  function handleClick(index: number) {
    if (active !== index) {
      setActive(index);
      navigateToId('agenda');
    }
  }

  return (
    <Section color={theme.colors.neutral.default} anchorId="agenda">
      <Wrapper>
        <Container>
          <UpperText $type="primary">{upperLabel}</UpperText>
          <H2 dangerouslySetInnerHTML={{ __html: title }} $type="primary" />
          <Nav active={active} handleClick={handleClick} dates={agendaEntries} fixed={inView} />
          <div ref={ref}>
            <Event event={agendaEntries[active]} />
          </div>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default Agenda;
