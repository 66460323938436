import Link from 'next/link';
import { useContext } from 'react';

import { Container, Section } from '@/components/core';
import { SecondaryButton } from '@/components/core/Button';
import { H2, UpperText } from '@/components/core/Title';
import { VenueSlider } from '@/components/shared';

import { Wrapper, Header, Address, VenueActions, DirectionButton, SliderWrapper } from './styles';

import EventContext from '@/contexts/event';
import { useUrlAttributes } from '@/utils/useUrlAttributes';

const Venue = () => {
  const { venue } = useContext(EventContext);

  const {
    address,
    address2,
    redButtonLabel,
    redButtonLink,
    secondaryButtonLabel,
    secondaryButtonLink,
    title,
    upperLabel,
  } = venue;

  const redButtonUrlAttributes = useUrlAttributes(redButtonLink);
  const secondaryButtonUrlAttributes = useUrlAttributes(secondaryButtonLink);

  return (
    <Section anchorId="venue">
      <Wrapper>
        <Container>
          <Header>
            <UpperText $type="primary">{upperLabel}</UpperText>
            <H2 dangerouslySetInnerHTML={{ __html: `${title}` }} $type="primary" />
            <Address dangerouslySetInnerHTML={{ __html: `${address}` }} />
            {address2 && <Address dangerouslySetInnerHTML={{ __html: `${address2}` }} />}
            <VenueActions>
              <DirectionButton
                big
                href={redButtonLink}
                forwardedAs={Link}
                {...redButtonUrlAttributes}
              >
                {redButtonLabel}
              </DirectionButton>

              <SecondaryButton
                big
                href={secondaryButtonLink}
                forwardedAs={Link}
                {...secondaryButtonUrlAttributes}
              >
                {secondaryButtonLabel}
              </SecondaryButton>
            </VenueActions>
          </Header>
        </Container>
        <SliderWrapper>
          <VenueSlider content={venue.images} />
        </SliderWrapper>
      </Wrapper>
    </Section>
  );
};

export default Venue;
