import moment from 'moment';
import React, { useContext } from 'react';

import DataTimeDisplay from './DataTimeDisplay/DataTimeDisplay';

import Section from '@/components/core/Section';
import { MarkdownRichText } from '@/components/shared';

import {
  ContainerElement,
  Header,
  HeaderWrapper,
  CircleShape,
  TriangleShape,
  HeaderTitle,
  HeaderEventDate,
  DisplayWrapper,
  TriangleShapeTwo,
  TriangleShapeThree,
  TriangleShapeFour,
  TriangleShapeFive,
} from './styles';

import EventContext from '@/contexts/event';

export default function Countdown() {
  const { info, header } = useContext(EventContext);

  const { date, location, endDate } = info;
  const { title } = header;

  return (
    <Section paddingXs={{ top: 164 }} paddingSm={{ top: 208 }} paddingMd={{ top: 304 }}>
      <ContainerElement>
        <Header>
          <HeaderWrapper>
            <CircleShape />
            <TriangleShape />
            <HeaderTitle>
              <MarkdownRichText>{title}</MarkdownRichText>
            </HeaderTitle>
            <HeaderEventDate>
              {`${moment(date).format('MMMM DD')}-${moment(endDate).format('DD')} at ${location}`}
            </HeaderEventDate>
          </HeaderWrapper>
        </Header>
        <DisplayWrapper>
          <DataTimeDisplay />
        </DisplayWrapper>
        <TriangleShapeTwo />
        <TriangleShapeThree />
        <TriangleShapeFour />
        <TriangleShapeFive />
      </ContainerElement>
    </Section>
  );
}
