import styled, { css } from 'styled-components';

import CircleShapeSVG from '@/public/assets/join-circle.svg';

interface WrapperType {
  $minimal?: boolean;
}

export const Wrapper = styled.div<WrapperType>`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  ${(props) =>
    !props.$minimal &&
    css`
      padding: 176px 0 550px;

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        padding: 78px 0 316px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        padding: 92px 0 160px;
      }

      svg {
        opacity: 0.05;
      }
    `}
`;

export const CircleShape = styled(CircleShapeSVG)`
  position: absolute;
  top: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    transform: scale(0.71);
    top: -110px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    transform: scale(0.53);
    top: -183px;
  }
`;
