import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  padding: 40px;
  gap: 8px;
  width: 200px;
  height: 280px;
  min-width: 280px;
  background-color: ${(props) => props.theme.colors.primary.light};
  z-index: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    min-width: 200px;
    height: 200px;
    padding: 24px;
    gap: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    border-radius: 8px;
    height: min-content;
    padding: 12px 16px 16px;
  }
`;

export const Stats = styled.h1`
  font-family: ${({ theme }) => theme.fonts.title};
  color: ${({ theme }) => theme.colors.grey.dark};

  font-weight: 700;
  font-size: 76px;
  line-height: 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 45px;
    line-height: 56px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

export const Description = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.fonts.title};
`;
