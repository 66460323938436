import styled, { css } from 'styled-components';

import { RedButton } from '@/components/core/Button';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SliderWrapper = styled.div`
  ${({ theme }) => css`
    height: 240px;
    margin-top: 40px;

    @media (min-width: ${theme.breakpoints.min_md}px) {
      height: 320px;
      margin: 64px auto 0;
    }
    @media (min-width: ${theme.breakpoints.min_lg}px) {
      height: 500px;
    }
  `}
`;

export const Address = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fonts.body};
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    color: ${theme.colors.grey.default};
    text-align: center;
  `}
`;

export const VenueActions = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: 24px;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;

    @media (min-width: 435px) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      max-width: 397px;
    }

    @media (min-width: ${theme.breakpoints.min_md}px) {
      margin-top: 32px;
      flex-direction: row;
      align-items: center;
    }
  `}
`;

export const DirectionButton = styled(RedButton)`
  margin-bottom: 8px;

  @media (min-width: 435px) {
    margin-bottom: 0;
  }
`;
