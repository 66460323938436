import { useContext, useMemo } from 'react';
import Link from 'next/link';
import moment from 'moment';
import cn from 'classnames';

import EventContext from '@/contexts/event';
import { getImgSrcWorkaround, getImgData } from '@/utils/images';
import { useUrlAttributes } from '@/utils/useUrlAttributes';
import { themeEventName } from '@/utils/themeEventName';

import {
  Direction,
  Hours,
  Images,
  Image,
  Point,
  Points,
  Title,
  Wrapper,
  Button,
  Content,
} from './eventStyles';

export type EventType = {
  className?: string;
  directions: string;
  end: string;
  extras: string;
  id: string;
  start: string;
  title: string;
  extra: string;
  firstImage: any;
  secondImage: any;
};

export function Event({
  className,
  start,
  end,
  title,
  extra,
  directions,
  firstImage,
  secondImage,
}: EventType) {
  const { themeEvent } = useContext(EventContext);
  const isAiSummit = useMemo(() => themeEvent === themeEventName.aiSummit, [themeEvent]);

  const startDate = moment(start, 'HH:mm:ss').format(isAiSummit ? 'hh:mm' : 'hh:mm A');
  const endDate = moment(end, 'HH:mm:ss').format(isAiSummit ? 'hh:mm a' : 'hh:mm A');

  const extras = extra ? extra.split(', ') : null;
  const urlAttributes = useUrlAttributes(directions);

  const firstImageContent = getImgData(firstImage);
  const secondImageContent = getImgData(secondImage);

  return (
    <>
      <Wrapper className={className}>
        <Hours className={cn({ isAiSummit })}>{`${startDate}–${endDate}`}</Hours>

        <Content>
          <Title className={cn({ isAiSummit })}>{title}</Title>

          {extras && (
            <Points>{extras?.map((extra: string) => <Point key={extra}>{extra}</Point>)}</Points>
          )}

          {directions && (
            <Direction>
              <Button
                className={cn({ isAiSummit })}
                href={directions}
                forwardedAs={Link}
                {...urlAttributes}
              >
                Get directions
              </Button>
            </Direction>
          )}

          {(firstImageContent || secondImageContent) && (
            <Images>
              {firstImageContent && (
                <Image
                  src={getImgSrcWorkaround(firstImageContent?.url)}
                  alt={firstImageContent?.alternativeText || ''}
                />
              )}
              {secondImageContent && (
                <Image
                  src={getImgSrcWorkaround(secondImageContent?.url)}
                  alt={secondImageContent?.alternativeText || ''}
                />
              )}
            </Images>
          )}
        </Content>
      </Wrapper>
    </>
  );
}

export default Event;
