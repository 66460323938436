import styled from 'styled-components';

import Speaker, { SpeakerProps } from './Speaker';
import { useContext } from 'react';
import EventContext from '@/contexts/event';

const Grid = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 64px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 32px;
  }
`;

const Speakers = ({ className, speakers }: { className?: string; speakers: SpeakerProps[] }) => {
  const { themeEvent } = useContext(EventContext);

  if (!speakers) return null;

  return (
    <Grid className={className}>
      {speakers.map((speaker) => (
        <Speaker {...speaker} key={speaker.id} themeEvent={themeEvent} />
      ))}
    </Grid>
  );
};

export default Speakers;
