import { useContext, useEffect, useState } from 'react';

import {
  TimerInner,
  TimerSegment,
  TimerValue,
  TimerLabel,
  TimerLabelMin,
  TimerLabelSec,
} from './styles';

import EventContext from '@/contexts/event';

function formatNumber(value: number) {
  return value < 10 ? `0${value}` : value;
}

const UNIT_FORMAT = {
  day: (v: number) => Math.floor(v / (1000 * 60 * 60 * 24)),
  hour: (v: number) => formatNumber(Math.floor((v % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))),
  minute: (v: number) => formatNumber(Math.floor((v % (1000 * 60 * 60)) / (1000 * 60))),
  second: (v: number) => formatNumber(Math.floor((v % (1000 * 60)) / 1000)),
};

const Display = () => {
  const { info } = useContext(EventContext);
  const [difference, setDifference] = useState(0);

  function setValues() {
    const now = new Date();
    const target = new Date(info.date);

    setDifference(target.getTime() - now.getTime());
  }

  useEffect(() => {
    setValues();

    const interval = setInterval(() => {
      setValues();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <TimerInner $isShown={!!difference}>
      <TimerSegment>
        <TimerValue>{UNIT_FORMAT['day'](difference)}</TimerValue>
        <TimerLabel>Days</TimerLabel>
      </TimerSegment>
      <TimerSegment>
        <TimerValue>{UNIT_FORMAT['hour'](difference)}</TimerValue>
        <TimerLabel>Hours</TimerLabel>
      </TimerSegment>
      <TimerSegment>
        <TimerValue>{UNIT_FORMAT['minute'](difference)}</TimerValue>
        <TimerLabelMin></TimerLabelMin>
      </TimerSegment>
      <TimerSegment>
        <TimerValue>{UNIT_FORMAT['second'](difference)}</TimerValue>
        <TimerLabelSec></TimerLabelSec>
      </TimerSegment>
    </TimerInner>
  );
};

export default Display;
