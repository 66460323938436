import { useState, useContext, useMemo } from 'react';
import styled, { keyframes, css } from 'styled-components';
import cn from 'classnames';
import { H3, UpperText } from '@/components/core/Title';
import { Container, Paragraph, Section } from '@/components/core';
import { Accordion, AccordionItem } from '@/components/core/Accordion';
import Footer from '@/components/core/Footer';

import theme from '@/utils/styles-variables';

import EventContext from '@/contexts/event';
import { TagContent } from '@/components/hackathon/TagContent';
import { themeEventName } from '@/utils/themeEventName';

type MoreInfoType = {
  isAnimation?: boolean;
  darkTheme?: boolean;
  withTagNames?: boolean;
};

type MoreInfoStyledType = {
  $animated?: boolean;
  $darkTheme?: boolean;
  $bottomSpacing?: boolean;
};

const MoreInfo = ({
  isAnimation = false,
  darkTheme = false,
  withTagNames = false,
}: MoreInfoType) => {
  const { moreInfo, moreInfoFaqs, themeEvent } = useContext(EventContext);

  const [extendedAccordionItemIndex, setExtendedAccordionItemIndex] = useState(null);
  const isAiSummit = useMemo(() => themeEvent === themeEventName.aiSummit, [themeEvent]);

  if (!moreInfo || !moreInfoFaqs) return null;

  const { description, upperLabel, title, disclaimer } = moreInfo;

  const handleAccordionToggle = (i: any) => {
    setExtendedAccordionItemIndex(extendedAccordionItemIndex === i ? null : i);
  };

  return (
    <Wrapper id="more-info">
      <Section
        as="div"
        paddingMd={{
          top: darkTheme ? '0' : isAiSummit ? 136 : 200,
          bottom: isAnimation ? 256 : isAiSummit ? 136 : 200,
        }}
        paddingXs={{ top: darkTheme ? '0' : 64, bottom: isAnimation ? 170 : 64 }}
        paddingSm={{ top: darkTheme ? '0' : 104, bottom: isAnimation ? 192 : 104 }}
        color={
          darkTheme
            ? 'transparent'
            : isAiSummit
              ? 'rgba(7, 138, 236,1)'
              : isAnimation
                ? 'rgba(41,114,168,1)'
                : theme.colors.grey.dark
        }
      >
        {isAnimation && (
          <>
            <LayerOne className={cn({ isAiSummit })} />
            <LayerTwo className={cn({ isAiSummit })} />
          </>
        )}

        <Container as="section">
          <Header
            className={cn({ isAiSummit })}
            $animated={isAnimation}
            $bottomSpacing={!!description}
          >
            {upperLabel && (
              <CustomUpperText
                as="p"
                $type="secondary"
                $animated={isAnimation}
                $darkTheme={darkTheme}
              >
                {upperLabel}
              </CustomUpperText>
            )}
            <Title
              dangerouslySetInnerHTML={{ __html: `${title}` }}
              type="secondary"
              as={withTagNames ? TagContent : undefined}
              tagName="h3"
              forwardedAs="h3"
            />
            {description && (
              <Description
                richText
                forwardedAs={withTagNames ? TagContent : undefined}
                markdownProps={{ elements: ['a', 'br', 'strong'] }}
                size="lg"
              >
                {description}
              </Description>
            )}
          </Header>
          <CustomAccordion
            className={cn({ isAiSummit })}
            $animated={isAnimation}
            $darkTheme={darkTheme}
          >
            {moreInfoFaqs?.map((a: any, i: number) => (
              <CustomAccordionItem
                key={a.id}
                title={a.question}
                expanded={i === extendedAccordionItemIndex}
                handleClick={() => handleAccordionToggle(i)}
                $animated={isAnimation}
                $darkTheme={darkTheme}
                className={cn({ isAiSummit })}
              >
                {a.answer}
              </CustomAccordionItem>
            ))}
          </CustomAccordion>
          {disclaimer && <Disclaimer size="sm">{disclaimer}</Disclaimer>}
        </Container>
      </Section>
      {isAnimation && <Footer absolute />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const layerOneAnimation = keyframes`
  0%{opacity: .6}
  50%{opacity: 1}
  100%{opacity: .6}
`;
const layerTwoAnimation = keyframes`
  0%{opacity: .0}
  50%{opacity: 1}
  100%{opacity: .0}
`;

const LayerOne = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    49deg,
    rgba(41, 114, 168, 0) 38%,
    rgba(229, 9, 43, 1) 98%,
    rgba(229, 9, 43, 1) 100%
  );
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  animation: ${layerOneAnimation} 10s infinite linear;
  pointer-events: none;

  &.isAiSummit {
    background: linear-gradient(
      49deg,
      rgba(7, 138, 236, 0) 38%,
      rgba(255, 0, 36, 1) 98%,
      rgba(255, 0, 36, 1) 100%
    );
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    background: linear-gradient(
      49deg,
      rgba(41, 114, 168, 0) 60%,
      rgba(229, 9, 43, 0.8) 98%,
      rgba(229, 9, 43, 0.8) 100%
    );

    &.isAiSummit {
      background: linear-gradient(
        49deg,
        rgba(7, 138, 236, 0) 60%,
        rgba(255, 0, 36, 0.8) 98%,
        rgba(255, 0, 36, 0.8) 100%
      );
    }
  }
`;
const LayerTwo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    211deg,
    rgba(41, 114, 168, 0) 35%,
    rgba(229, 9, 43, 1) 98%,
    rgba(229, 9, 43, 1) 100%
  );
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  animation: ${layerTwoAnimation} 15s infinite linear;
  pointer-events: none;

  &.isAiSummit {
    background: linear-gradient(
      211deg,
      rgba(7, 138, 236, 0) 35%,
      rgba(255, 0, 36, 1) 98%,
      rgba(255, 0, 36, 1) 100%
    );
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    background: linear-gradient(
      211deg,
      rgba(41, 114, 168, 0) 60%,
      rgba(229, 9, 43, 0.8) 98%,
      rgba(229, 9, 43, 0.8) 100%
    );
    &.isAiSummit {
      background: linear-gradient(
        211deg,
        rgba(7, 138, 236, 0) 60%,
        rgba(255, 0, 36, 0.8) 98%,
        rgba(255, 0, 36, 0.8) 100%
      );
    }
  }
`;

const CustomUpperText = styled(UpperText)<MoreInfoStyledType>`
  ${(props) =>
    props.$animated &&
    css`
      color: inherit;
    `};
`;

const Header = styled.header<MoreInfoStyledType>`
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &.isAiSummit {
    padding-bottom: 48px;
  }

  ${(props) =>
    props.$animated &&
    css`
      color: ${theme.colors.white};
      z-index: 2;
      position: relative;
    `};

  ${(props) =>
    props.$bottomSpacing &&
    css`
      padding-bottom: 80px;

      &.isAiSummit {
        padding-bottom: 80px;
      }
    `};
`;
const Title = styled(H3)`
  line-height: 56px;
  font-size: 45px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 25px;
    line-height: 32px;
  }
`;

const Description = styled(Paragraph)`
  max-width: 750px;
  color: ${(props) => props.theme.colors.white};
`;

const CustomAccordion = styled(Accordion)<MoreInfoStyledType>`
  max-width: 1050px;
  position: relative;
  margin: 0 auto 0 auto;

  ${(props) =>
    props.$animated &&
    css`
      margin: 0 auto 0 auto;
      z-index: 2;
    `};

  @media (min-width: ${theme.breakpoints.min_md}px) {
    border-radius: 8px;
    background-color: ${theme.colors.grey.dark};
    border: 1px solid ${theme.colors.grey[800]};

    &.isAiSummit {
      border: 0;
    }

    ${(props) =>
      props.$animated &&
      css`
        background-color: var(--background-more-info-animated, rgba(31, 34, 44, 0.3));
        border: 1px solid rgba(31, 34, 44, 0.3);
      `};

    ${(props) =>
      props.$darkTheme &&
      css`
        background-color: ${(props) => props.theme.colors.grey.dark}99;
        border-color: ${(props) => props.theme.colors.grey.dark}99;
      `};
  }
`;

const CustomAccordionItem = styled(AccordionItem)<MoreInfoStyledType>`
  padding: 16px 0;

  &.isAiSummit {
    border-top: 1px solid ${(props) => props.theme.colors.grey.dark};
    p {
      font-weight: var(--title-font-weight, 700);
      color: ${(props) => props.theme.colors.grey[200]};
    }

    .title-wrapper {
      span {
        font-family: var(--title-font);
        font-size: 16px;
        line-height: 28px;
        font-weight: var(--title-font-weight, 700);
        text-transform: uppercase;
      }
    }
  }

  &:first-child {
    border-top: 1px solid transparent;
  }

  ${(props) =>
    props.$animated &&
    css`
      h3 {
        &.title-wrapper {
          i {
            background-color: var(--background-icon-more-info-animated, rgba(31, 34, 44, 0.3));
          }
        }
      }
    `};

  .title-wrapper {
    span {
      font-size: 18px;
      font-weight: 500;
      color: ${(props) => props.theme.colors.grey.dark};
    }

    i {
      min-height: 30px;
      min-width: 30px;
      padding: 7.5px;
      background-color: ${(props) => props.theme.colors.primary.light};
      border-radius: 50%;

      &:before,
      &:after {
        background-color: ${(props) => props.theme.colors.grey.dark};
        height: 1px;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding: 27px 40px;

    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding: 27px 64px 29px 67px;
  }
`;

const Disclaimer = styled(Paragraph)`
  max-width: 750px;
  font-style: italic;
  text-align: center;
  margin: 30px auto 0 auto;
  color: ${(props) => props.theme.colors.white};
  font-family: var(--title-font);
`;

export default MoreInfo;
