import styled from 'styled-components';
import Image from 'next/image';
import Shape from '@/public/assets/full-shape.svg';
import CircleShapeSVG from '@/public/assets/event-top-shape.svg';

type RowType = {
  $positioning: number;
};

export const Wrapper = styled.div`
  overflow: hidden;
`;

export const Row = styled.div<RowType>`
  display: flex;
  gap: 24px;
  padding-top: 24px;
  position: relative;
  flex-direction: ${({ $positioning }) => ($positioning % 2 ? 'row-reverse' : 'row')};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    align-items: ${({ $positioning }) => ($positioning % 2 ? 'flex-start' : 'flex-end')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
    gap: 8px;
    padding-top: 8px;
  }
`;

export const ImageWrapper = styled.div<RowType>`
  height: 440px;
  position: relative;
  width: ${({ $positioning }) => ($positioning % 2 ? 728 : 835)}px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 320px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
    max-height: 200px;
  }
`;

export const CustomImage = styled(Image)`
  height: 440px;
  border-radius: 16px;
  object-fit: cover;
  object-position: center;
  z-index: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    border-radius: 8px;
  }
`;

export const FullShape = styled(Shape)`
  position: absolute;
  right: 495px;
  bottom: -31px;
  height: 301px;
  width: 301px;
  z-index: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

export const CircleShape = styled(CircleShapeSVG)`
  position: absolute;
  top: 168px;
  left: -250px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

export const BottomCircleShape = styled(CircleShapeSVG)`
  transform: rotate(150deg);
  position: absolute;
  right: -250px;
  bottom: -370px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;
