import styled, { css } from 'styled-components';
import theme from '@/utils/styles-variables';

type TimerInnerType = {
  $isShown: boolean;
};

export const TimerInner = styled.div<TimerInnerType>`
  ${({ $isShown }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${$isShown ? '1' : '0'};
    transition: opacity 0.25s ease-in-out;
  `}
`;
export const TimerSegment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;

  @media (min-width: ${theme.breakpoints.min_md}px) {
    flex-direction: column-reverse;
  }
`;
export const TimerValue = styled.span`
  font-family: ${theme.fonts.title};
  font-size: 25px;
  line-height: 32px;
  font-weight: 700;
  color: ${theme.colors.grey[800]};

  @media (min-width: ${theme.breakpoints.min_md}px) {
    font-size: 55px;
    line-height: 64px;
  }
  @media (min-width: ${theme.breakpoints.min_lg}px) {
    font-size: 76px;
    line-height: 80px;
  }
`;
export const TimerLabel = styled.span`
  font-family: ${theme.fonts.title};
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  color: ${theme.colors.grey.default};

  @media (min-width: ${theme.breakpoints.min_md}px) {
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`;
export const TimerLabelMin = styled(TimerLabel)`
  &:before {
    content: 'Min.';

    @media (min-width: ${theme.breakpoints.min_md}px) {
      content: 'Minutes';
    }
  }
`;
export const TimerLabelSec = styled(TimerLabel)`
  &:before {
    content: 'Sec.';

    @media (min-width: ${theme.breakpoints.min_md}px) {
      content: 'Seconds';
    }
  }
`;
