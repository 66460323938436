import React, { useContext } from 'react';

import { Container, Section } from '@/components/core';

import Card from './Card';
import Header from './Header';

import {
  Row,
  CustomImage,
  FullShape,
  ImageWrapper,
  CircleShape,
  Wrapper,
  BottomCircleShape,
} from './styles';

import { getImgSrcWorkaround } from '@/utils/images';

import EventContext from '@/contexts/event';

type ListProps = {
  id: string;
  title: string;
  description: string;
  image: {
    data: {
      attributes: {
        url: string;
        alternativeText: string;
        name: string;
      };
    };
  };
};

const Event = () => {
  const { event } = useContext(EventContext);

  const { list } = event;
  return (
    <Wrapper>
      <Section
        paddingXs={{ top: '70' }}
        paddingSm={{ top: '70' }}
        paddingMd={{ top: '0' }}
        color="linear-gradient(180deg, rgba(247, 244, 242, 0) 0%, #F7F4F2 27.6%)"
        anchorId="event"
      >
        <Container style={{ position: 'relative', zIndex: 2 }}>
          <Header />

          {list?.map((item: ListProps, index: number) => (
            <Row $positioning={index} key={item.id}>
              {index % 2 !== 0 && <FullShape />}
              <ImageWrapper $positioning={index}>
                <CustomImage
                  fill
                  sizes="100%"
                  src={getImgSrcWorkaround(item.image.data.attributes.url)}
                  alt={
                    item.image.data.attributes.alternativeText || item.image.data.attributes.name
                  }
                />
              </ImageWrapper>
              <Card stats={item.title} description={item.description} />
            </Row>
          ))}
          <CircleShape />
          <BottomCircleShape />
        </Container>
      </Section>
    </Wrapper>
  );
};

export default Event;
