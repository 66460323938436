import styled from 'styled-components';

import { SecondaryButton } from '@/components/core/Button';

export const Wrapper = styled.div`
  font-family: ${(props) => props.theme.fonts.title};
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 24px 0;
  border-bottom: 1px solid #d8dbe1;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
    gap: 8px;
    padding: 20px 0;
  }
`;

export const Hours = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--hours-color, ${(props) => props.theme.colors.grey.default});
  min-width: 212px;

  &.isAiSummit {
    font-size: 18px;
  }
`;

export const Title = styled.div`
  font-weight: var(--title-font-weight, 700);
  font-size: 20px;
  line-height: 24px;

  &.isAiSummit {
    font-family: var(--ui-font-family-gilmer);
  }
`;

export const Points = styled.div`
  display: flex;
  margin-top: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
    margin-top: 8px;
  }
`;

export const Point = styled.div`
  font-family: ${(props) => props.theme.fonts.body};
  font-weight: var(--title-font-weight, 500);
  font-size: 16px;
  line-height: 28px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    &:not(:first-child) {
      position: relative;
      padding-left: 20px;
      &:before {
        position: absolute;
        content: '';
        top: 14px;
        left: 8px;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background-color: #1f222c;
      }
    }
  }
`;

export const Direction = styled.div`
  margin-top: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 8px;
  }
`;

export const Content = styled.div`
  width: 100%;
`;

export const Images = styled.div`
  margin-top: 24px;
  display: flex;
  width: 100%;
  gap: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const Image = styled.img`
  width: 50%;
  max-width: 50%;
  height: 320px;
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 184px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    max-width: 100%;
    height: 160px;
  }
`;

export const Button = styled(SecondaryButton)`
  height: 32px;
  padding: 0 32px;
  font-weight: var(--title-font-weight, 500);

  &.isAiSummit {
    padding: 0 16px;
    text-transform: none;
    font-family: var(--ui-font-family-body);
    line-height: 24px;
  }
`;
