import React, { memo } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import classNames from 'classnames';

import { getImgData, getImgSrcWorkaround } from '@/utils/images';
import { themeEventName } from '@/utils/themeEventName';
import { MarkdownRichText } from '@/components/shared';

const Wrapper = styled.li`
  width: 217px;
  margin: 0 10px 48px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 24px;
  }

  &.rounded {
    margin: 0 10px 48px;
    width: 217px;
  }

  &:not(rounded) {
    margin: 0 12px 48px;
    width: 190px;
  }
`;

const PersonImage = styled.figure`
  margin: auto;
  position: relative;
  overflow: hidden;

  img {
    object-fit: cover;
  }

  &:not(rounded) {
    border-radius: 5px;
    height: 255px;
    width: 100%;
  }

  &.rounded {
    height: 161px;
    width: 161px;
    border-radius: 50%;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      height: 128px;
      width: 128px;
    }
  }
`;

const PersonInfo = styled.div`
  max-width: 217px;
  margin-top: 24px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-left: auto;
    margin-right: auto;
    padding: 0 8px;
  }

  &.aiSummit {
    margin-top: 16px;
    text-align: left;
  }
`;

const PersonName = styled.p`
  font-family: ${(props) => props.theme.fonts.title};
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;

  .aiSummit > & {
    font-size: 24px;
    font-style: italic;
    line-height: 18px;
    font-weight: 700;
    text-transform: uppercase;

    &::first-line {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
    }
  }
`;
const PersonBio = styled.p`
  font-size: 16px;
  line-height: 23px;
  color: ${(props) => props.theme.colors.grey.default};
  white-space: pre-line;

  .aiSummit > & {
    font-family: var(--title-font);
    font-size: 12px;
    line-height: 16px;
  }
`;
const LogosWrap = styled.footer`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px 0 16px;
  margin-top: 8px;
`;
const Logo = styled(Image)`
  display: block;
  flex-grow: 1;
  flex-shrink: 0;
  height: auto;
  max-height: 18px;
  max-width: 110px;
  width: auto;
`;

type LogosProps = {
  id: number;
  url: string;
  alternativeText: string;
  width: number;
  height: number;
  updatedAt?: string;
};

export type SpeakerProps = {
  id: string;
  photo: {
    data: any;
  };
  name?: string;
  bio?: string;
  profileUrl?: string;
  themeEvent?: string;
  logos?: LogosProps[];
};

function Speaker({ name, bio, photo, profileUrl, themeEvent, logos }: SpeakerProps) {
  const aiSummit = themeEvent === themeEventName.aiSummit;
  const image = getImgData(photo);
  const LinkWrapper = profileUrl ? 'a' : React.Fragment;
  const urlAtributes = profileUrl
    ? { href: profileUrl, target: '_blank', rel: 'noopener noreferrer' }
    : {};
  return (
    <Wrapper className={classNames({ rounded: !aiSummit })}>
      <LinkWrapper {...urlAtributes}>
        <PersonImage className={classNames({ rounded: !aiSummit })}>
          <Image
            src={getImgSrcWorkaround(image?.url, image?.updatedAt)}
            alt={name || ''}
            fill
            sizes="190px"
          />
        </PersonImage>
        <PersonInfo className={classNames({ aiSummit })}>
          {name && (
            <PersonName>
              <MarkdownRichText elements={['br']}>
                {name.replace(/\n/g, '<br/>&nbsp;')}
              </MarkdownRichText>
            </PersonName>
          )}
          {bio && <PersonBio>{bio}</PersonBio>}
          {logos && aiSummit && (
            <LogosWrap>
              {logos?.map((item) => (
                <Logo
                  key={item.id}
                  src={getImgSrcWorkaround(item.url, item.updatedAt)}
                  alt={item.alternativeText || ''}
                  width={(item.width * 18) / item.height}
                  height={18}
                />
              ))}
            </LogosWrap>
          )}
        </PersonInfo>
      </LinkWrapper>
    </Wrapper>
  );
}

export default memo(Speaker);
